import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby"
import styled from "styled-components";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import SEO from "../components/SEO";

import PostDetail from "./PostDetail";

const StyledPostRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

const BlogDetail = ({data}) => {
  const { markdownRemark: post } = data

  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Section className="pb-0">
          <div className="pt-5"></div>
          <SEO
            title={`Instant Demo | ${post.frontmatter.title}`}
            sharingTitle={post.frontmatter.title}
            description={post.frontmatter.description}
            relativePath={`blog${post.fields.slug}`}
            imageUrl={post.frontmatter.featuredimage.childImageSharp.fluid.src}
          />
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                  {post.frontmatter.title}
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>
                    {post.frontmatter.date}
                  </Text>
                  <Text mr={3}>
                    |
                  </Text>
                  <Text mr={3}>
                    {post.fields.readingTime.text}
                  </Text>
                  <Text mr={3}>
                    |
                  </Text>
                  <Text>
                    {post.frontmatter.author}
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-5">
          <Container>
            <StyledPostRow>
              <Col lg="8" className="mb-5">
                <PostDetail
                  tags={post.frontmatter.tags}
                  html={post.html}
                  featuredImage={post.frontmatter.featuredimage}
                />
              </Col>
            </StyledPostRow>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default BlogDetail;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`