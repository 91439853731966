import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import Img from "gatsby-image"

import { Box, Badge } from "../components/Core";

import iconQuote from "../assets/image/blog/quote-icon.png";

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading};
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    transition: all 0.3s ease-out;
    &:hover, &:active, &:focus{
      color: ${({ theme }) => theme.colors.primaryHover};
      text-decoration: none!important;
      outline: none !important;
    }
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const PostDetail = ({tags, html, featuredImage}) => {
  return (
    <>
      {/* <!-- Blog section --> */}
      <Post>
        <div>
          <Img
            className="img-fluid"
            fluid={featuredImage.childImageSharp.fluid}
            alt="Featued Image"
          />
        </div>
        <div className="mt-8" dangerouslySetInnerHTML={{ __html: html }} />
      </Post>
      <Box className="d-flex" mt={4}>
        {tags.map((tag) => {
          return (
            <BadgePost key={tag}>{tag}</BadgePost>
          )
        })}
      </Box>
    </>
  )
}

PostDetail.propTypes = {
  tags: PropTypes.array,
  html: PropTypes.node,
  featuredImage: PropTypes.object
}

export default PostDetail;
